import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '../../theme';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { PopoverState } from './popover';

const PopoverItemContainer = styled.div<{
  state: PopoverState;
  customStyles?: string;
}>`
  min-width: 100%;
  width: max-content;
  background-color: ${theme.tokens.color.global.white.value};
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 14px 16px;
  max-height: 48px;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

  ${({ state, theme }) =>
    state === 'active'
      ? css`
          &:hover .dropdownItem-stateLayer {
            background-color: ${theme.tokens.color.specific['state-layer'][
              'state-hover-darker'
            ].value};
          }

          &:active .dropdownItem-stateLayer {
            background-color: ${theme.tokens.color.specific['state-layer'][
              'state-press-darker'
            ].value};
          }
        `
      : css`
          svg,
          path {
            fill: ${state === 'disabled'
              ? theme.tokens.color.alias.cm.icon['icon-disabled'].value
              : theme.tokens.color.alias.cm.icon['icon-default'].value};
          }
        `}

  ${({ state }) =>
    state === 'disabled' &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${theme.tokens.color.alias.cm.text['text-disabled'].value};
    `}
`;

const Subtitle = styled(Typography.Body3)<{ state: PopoverState }>`
  margin-top: -2px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  ${({ state }) =>
    state === 'disabled' &&
    css`
      color: ${theme.tokens.color.alias.cm.text['text-disabled'].value};
    `}
`;

const CustomButton = styled(Button)<{
  state: PopoverState;
  disabled?: boolean;
}>`
  ${({ state }) =>
    state === 'selected' &&
    css`
      .button-stateLayer {
        background-color: ${theme.tokens.color.specific['state-layer'][
          'state-hover-darker'
        ].value};
      }
    `}
`;

export { CustomButton, PopoverItemContainer, Subtitle };
