import styled from '@emotion/styled';
import {
  Grounder,
  Tabs,
  TitleHeader,
  Typography,
  theme,
} from '@innovamat/glimmer-components';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EarlyYearsAdminDashboard } from '../early-years-admin-dashboard';
import { PrimaryAdminDashboard } from '../primary-admin-dashboard';
import { SecondaryAdminDashboard } from '../secondary-admin-dashboard';

import { NoClassesError } from './components/no-classes-error';
import { ERROR_TYPE, ErrorPage } from '../../../navigation';
import ReportsLoader from '../../components/reports-loader';

type Props = {
  organizationId?: string;
  stagesAreLoading: boolean;
  stages: { [key: string]: boolean };
  permissions?: { [key: string]: boolean };
  isError?: boolean;
  searchParams: URLSearchParams;
  onSetSearchParams: (search: string) => void;
  isNewManager?: boolean;
};

type DashboardRendererProps = {
  stageActive: stageType;
};

type stageType = 'EarlyYears' | 'Primary' | 'Secondary';

const Wrapper = styled.div`
  margin-right: -24px;
`;

const Container = styled.div`
  @media (min-width: ${theme.breakpoints.xl}) {
    max-width: 1280px;
    margin: 0 auto;
  }
`;

const InnerContainer = styled.div``;

const Title = styled(TitleHeader)`
  position: sticky !important;
  left: 0 !important;
`;

const TableContainer = styled.div`
  display: grid;
  padding-right: 24px;
`;

const SubtitleHeader = styled(Typography.H4)`
  padding: 16px 0px 14px;
  position: sticky;
  left: 0;

  border-top: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

function AdminDashboard({
  organizationId,
  stagesAreLoading,
  stages,
  permissions,
  isError,
  onSetSearchParams,
  searchParams,
  isNewManager,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const stageFromURL = searchParams.get('stage');

  const getInitialStage = useMemo(
    () =>
      (
        stages: { [key: string]: boolean },
        permissions?: { [key: string]: boolean }
      ): stageType | void => {
        if (stageFromURL) return stageFromURL as stageType;

        if (stages.infantil && permissions?.infantil) return 'EarlyYears';
        if (stages.primaria && permissions?.primaria) return 'Primary';
        if (stages.secundaria && permissions?.secundaria) return 'Secondary';
      },
    [stageFromURL]
  );

  const [stageActive, setStageActive] = useState<stageType>(
    getInitialStage(stages, permissions) || 'EarlyYears'
  );

  useEffect(() => {
    if (!stageFromURL) {
      const initStage = getInitialStage(stages, permissions);
      if (initStage) {
        searchParams.set('stage', stageActive);
        onSetSearchParams(searchParams.toString());
      }
    }
  }, [
    getInitialStage,
    onSetSearchParams,
    searchParams,
    stageActive,
    stageFromURL,
    stages,
    permissions,
  ]);

  useEffect(() => {
    const appContent = document.getElementById('app-content');
    if (appContent) {
      appContent.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    }
    return () => {
      if (appContent) {
        appContent.style.overflow = '';
        document.body.style.overflow = 'scroll';
      }
    };
  }, []);

  const isStageActive = (section: stageType): boolean =>
    stageActive === section;

  const stagesMapping: {
    key: stageType;
    translation: string;
    stage: boolean;
    permission?: boolean;
  }[] = [
    {
      key: 'EarlyYears',
      translation: 'common.stage-infantil',
      stage: stages.infantil,
      permission: permissions?.infantil,
    },
    {
      key: 'Primary',
      translation: 'common.stage-primaria',
      stage: stages.primaria,
      permission: permissions?.primaria,
    },
    {
      key: 'Secondary',
      translation: 'common.stage-secundaria',
      stage: stages.secundaria,
      permission: permissions?.primaria,
    },
  ];

  const hasClasses = stages.infantil || stages.primaria || stages.secundaria;
  const hasPermissions =
    permissions?.infantil || permissions?.primaria || permissions?.secundaria;

  const handleChangeStage = (stage: stageType): void => {
    setStageActive(stage);
    searchParams.set('stage', stage);
    onSetSearchParams(searchParams.toString());
  };

  const DashboardRenderer: React.FC<DashboardRendererProps> = ({
    stageActive,
  }) => {
    if (stagesAreLoading) {
      return <ReportsLoader />;
    }

    if (isError || !hasPermissions) {
      return <ErrorPage errorType={ERROR_TYPE.NOT_AVAILABLE} />;
    }

    if (!stagesAreLoading && !hasClasses) {
      return <NoClassesError />;
    }

    const dashboardMap = {
      EarlyYears: (
        <EarlyYearsAdminDashboard
          organizationId={organizationId}
          tabsAreLoaded={hasClasses}
          isNewManager={isNewManager}
        />
      ),
      Primary: (
        <PrimaryAdminDashboard
          organizationId={organizationId}
          tabsAreLoaded={hasClasses}
          isNewManager={isNewManager}
        />
      ),
      Secondary: (
        <SecondaryAdminDashboard
          organizationId={organizationId}
          tabsAreLoaded={hasClasses}
          isNewManager={isNewManager}
        />
      ),
    };

    return dashboardMap[stageActive] || null;
  };

  return (
    <Wrapper>
      <Container>
        <InnerContainer>
          <Title
            title={t('sidebar.items.adminDashboard')}
            hasSeparator={false}
          />
          <Tabs
            tabs={stagesMapping
              .filter((stage) => stage.stage && stage.permission)
              .map((stage) => ({
                selected: isStageActive(stage.key),
                text: t(stage.translation),
                onClick: () => handleChangeStage(stage.key),
                dataTestId: `ADRE_${stage.key.toUpperCase()}TAB`,
              }))}
          />
          {hasClasses && (
            <SubtitleHeader>{t('reports.admin.subtitle')}</SubtitleHeader>
          )}
        </InnerContainer>

        <TableContainer>
          <DashboardRenderer stageActive={stageActive} />
        </TableContainer>
      </Container>
      <Grounder />
    </Wrapper>
  );
}

export { AdminDashboard };
