import {
  Classroom,
  Course,
  initDatadog,
  inlineManual,
} from '@innovamat/radiance-utils';
import type { OrganizationResponse, User } from './user.d';

import { getOrganization, getStudent } from './api';
import { eventLogging } from '@innovamat/event-logging';
import { mapUserToInlineManualUser } from './mappers';
import { detectIsInIOS, detectIsInWebview } from '@innovamat/webview-bridge';

type GetStudent = Promise<{
  currentClassroom: Classroom;
  currentCourse: Course;
}>;

export const setStudent = async (
  userId: string,
  region: string
): GetStudent => {
  const userData = await getStudent(userId);

  const currentClassroom: Classroom = {
    courseName: userData.courseName,
    courseId: userData.courseId,
    icon: userData.classroomAvatar || userData.classroomIcon,
    courseOrder: userData.courseOrder,
    id: userData.classroomId,
    name: userData.classroomName,
    avatar: userData.avatar,
    level: userData.classroomLevel,
  };

  const currentCourse: Course = {
    id: userData.courseId,
    name: userData.courseName,
    order: userData.courseOrder,
    region: region,
  };

  return {
    currentClassroom,
    currentCourse,
  };
};

export const setOrganization = async (user: User): Promise<User> => {
  const baseUser = { ...user };
  const orgResponse: OrganizationResponse = await getOrganization(
    user.organizationId
  );

  const organization = {
    academicYearId: orgResponse.academicYearId,
    currency: orgResponse.currency,
    id: orgResponse.id,
    location: orgResponse.location,
    name: orgResponse.name,
    platformEnabled: orgResponse.platformEnabled,
    standard: orgResponse.standard,
  };

  // a organization has phisical region and operative region
  // we should use operative region

  baseUser.organization = organization;
  baseUser.organizationName = organization.name;
  // a organization has phisical region and operative region
  // we should use operative region
  baseUser.region = orgResponse.operativeRegion;
  baseUser.phisicalRegion = orgResponse.region;

  if (orgResponse.region !== orgResponse.operativeRegion) {
    // use the language of the operative region
    baseUser.language = orgResponse.language;
  }
  return baseUser;
};

export const runInitExternalLibraries = (user: User): void => {
  try {
    const {
      id,
      email,
      name,
      roles,
      organizationId,
      organizationName,
      crmId,
      region,
    } = user;

    eventLogging.log('sys_login', {
      username: email,
      name: name,
      role: roles?.join(),
      school_id: organizationId,
      school_name: organizationName,
      crm_id: crmId,
      app_version: import.meta.env.VITE_VERSION,
    });

    // Datadog RUM Monitoring
    const sampleRates = { sessionSampleRate: 15, sessionReplaySampleRate: 5 };
    const envVariables = {
      DATADOG_APPLICATION_ID: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      DATADOG_CLIENT_TOKEN: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      DATADOG_ENVIRONMENT: import.meta.env.VITE_DATADOG_ENVIRONMENT,
      DATADOG_SERVICE: import.meta.env.VITE_DATADOG_SERVICE,
      COMMIT_ID: import.meta.env.VITE_COMMIT_ID,
      CONFIG_CAT_KEY: import.meta.env.VITE_CONFIG_CAT_KEY,
    };
    initDatadog(envVariables, sampleRates, {
      uid: id,
      school: organizationId,
    });

    const isInWebView = detectIsInWebview();
    const isInIOS = detectIsInIOS();

    const inlineUser = mapUserToInlineManualUser(user, isInIOS, isInWebView);
    inlineUser.region = region;
    inlineManual.initOrUpdateInlineManualPlayer(inlineUser);

    eventLogging.setIterableEmail(user.email);
  } catch (error) {
    console.error(error);
  }
};
